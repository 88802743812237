import type { Configuration } from '@mews-commander/data-access';
import 'core-js/stable';

const getAppFilename = ({ extension }: { extension: string }) => `commanderApp.${extension}`;

export const start = ({ configuration, ...other }: Configuration, onInit: () => void) => {
    const filePath = __LOCALHOST__ && !__SERVE_FOR_BACKEND__ ? '/' : `${configuration.assetBaseUrl}/${__VERSION__}/`;

    const stylesheet = window.document.createElement('link');
    stylesheet.href = `${filePath}${getAppFilename({ extension: 'css' })}`;
    stylesheet.rel = 'stylesheet';
    window.document.head.appendChild(stylesheet);

    const script = window.document.createElement('script');
    script.src = `${filePath}${getAppFilename({ extension: 'js' })}`;
    script.onload = function onload() {
        window.Mews.startCommander({ configuration, ...other }, onInit);
    };
    window.document.head.appendChild(script);
};
